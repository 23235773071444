<template>
  <v-card elevation="2" color="background">
    <v-card-text style="text-align: center" class="mt-10">
      <span class="primary--text" style="font-size: 4em">404</span>
      <h1 class="ma-5">العنوان المطلوب غير موجود</h1>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  created() {
    this.$store.commit('theme/TOGGLE_DRAWER', false);
  },
  destroyed(){
    this.$store.commit('theme/TOGGLE_DRAWER', true);
  }
};
</script>

<style></style>
